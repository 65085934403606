<template>
  <div>
    <!-- Financing Rounds -->
    <v-row>
      <v-col cols="6">
        <validation-provider
          v-slot="{ errors }"
          :name="'Financing Round Name'"
          :rules="{ required: true, max: 255 }"
        >
          <common-input-text-field
            v-model="internalValue.name"
            label="Financing round name"
            color="primary"
            :error-messages="errors"
            :hide-details="errors.length === 0"
            @input="updateValue"
          />
        </validation-provider>
      </v-col>
      <v-col
        cols="12"
        md="6"
      >
        <validation-provider
          v-slot="{ errors }"
          :name="'Pre-money valuation'"
          :rules="{ required: false, min_value: 0 }"
        >
          <common-input-currency-input
            v-model="internalValue.pre_money_valuation"
            label="Pre-money Valuation"
            color="primary"
            :options="{ locale: 'en-US', precision: 2 }"
            prepend-inner-icon="mdi-currency-usd"
            :error-messages="errors"
            :hide-details="errors.length === 0"
          />
        </validation-provider>
      </v-col>
      <v-col
        cols="12"
        md="6"
      >
        <validation-provider
          v-slot="{ errors }"
          rules="required"
          :name="'Closing date'"
        >
          <input-template-date
            v-model="finallyClosedOn"
            :mask="'##/##/####'"
            :input-templ="{label: 'Closing date', mandatory: true}"
            :error-messages="errors"
            :hide-details="errors.length === 0"
            clearable
          />
        </validation-provider>
      </v-col>
    </v-row>
    <!-- Investors -->

    <v-row
      v-for="(item,key) in investorData"
      :key="key"
    >
      <v-col
        cols="12"
        md="6"
      >
        <validation-provider
          v-slot="{ errors }"
          :name="'Investor Name'"
          :rules="{ required: true, max: 255 }"
        >
          <common-input-text-field
            v-model="item.name"
            label="Investor name"
            color="primary"
            :error-messages="errors"
            :hide-details="errors.length === 0"
            @input="updateValue"
          />
        </validation-provider>
      </v-col>
      <v-col
        cols="12"
        md="4"
        sm="10"
      >
        <validation-provider
          v-slot="{ errors }"
          :name="'Invested capital'"
          :rules="{ required: true, min_value: 0 }"
        >
          <common-input-currency-input
            v-model="item.invested_capital"
            label="Invested capital"
            color="primary"
            :options="{ locale: 'en-US', precision: 2 }"
            prepend-inner-icon="mdi-currency-usd"
            :error-messages="errors"
            :hide-details="errors.length === 0"
            @input="updateValue"
          />
        </validation-provider>
      </v-col>
      <v-col
        cols="12"
        md="2"
        sm="2"
        class="mt-4"
      >
        <common-trs-btn
          type="tertiary"
          icon
          @click="addInvestor"
        >
          <v-icon>mdi-plus-circle-outline</v-icon>
        </common-trs-btn>
        <common-trs-btn
          class="delete-btn"
          type="tertiary"
          icon
          :disabled="investorData.length===1"
          @click="deleteInvestor"
        >
          <v-icon>mdi-delete</v-icon>
        </common-trs-btn>
      </v-col>
    </v-row>
    <!-- Liquidation Preference -->
    <v-row />
    <!-- intrest row -->
    <v-row>
      <interest-form
        v-model="interestData"
        @update="updateInterest"
      />
    </v-row>
    <v-row>
      <v-col
        cols="12"
        md="6"
      >
        <validation-provider
          v-slot="{ errors }"
          :name="'Multiple'"
          :rules="{ required: true}"
        >
          <common-input-currency-input
            v-model="liquidationPreference.multiple"
            label="Multiple (1x)"
            :options="{ locale: 'en-US', precision: 2}"
            color="primary"
            :error-messages="errors"
            :hide-details="errors.length === 0"
          />
        </validation-provider>
      </v-col>
      <v-col
        cols="12"
        md="6"
      >
        <validation-provider
          v-slot="{ errors }"
          :name="'Participating cap'"
          :rules="{ required: true}"
        >
          <common-input-currency-input
            v-model="participatingPreference.participating_cap"
            label="Participating cap (1x)"
            color="primary"
            :options="{ locale: 'en-US', precision: 2 }"
            :error-messages="errors"
            :hide-details="errors.length === 0"
          />
        </validation-provider>
      </v-col>
      <v-col cols="6">
        <validation-provider
          v-slot="{ errors }"
          :name="'Previous Class'"
          :rules="{ required: true }"
        >
          <common-input-select
            v-model="liquidationPreference.previous_class"
            label="Previous Class"
            placeholder="Previous Class"
            :items="CONSTS.INTREST_PRIORITY_TYPES"
            item-text="name"
            item-value="type"
            :error-messages="errors"
            :hide-details="errors.length === 0"
          />
        </validation-provider>
      </v-col>
      <v-col
        cols="6"
      >
        <validation-provider
          v-slot="{ errors }"
          :name="'Equity incentive plan shuffle'"
          :rules="{ required: false, max_value: 1 }"
        >
          <common-input-currency-input
            v-model="internalValue.equity_incentive_plan_shuffle"
            label="Equity incentive plan shuffle-%(Optional)"
            color="primary"
            :error-messages="errors"
            :hide-details="errors.length === 0"
            :options="{ locale: 'en-US', precision: 2 }"
            append-icon="mdi-percent-outline"
          />
        </validation-provider>
      </v-col>
      <!-- Convert Convertibles -->
      <v-col
        cols="12"
        lg="12"
        md="6"
      >
        <validation-provider
          v-slot="{ errors }"
          :name="'Convert Convertibles'"
          :rules="{ required: true }"
        >
          <div class="d-flex">
            <span>
              Convert Convertibles:
            </span>
            <span>
              <v-radio-group
                v-model="internalValue.convert_convertibles"
                column
                :error-messages="errors"
                :hide-details="errors.length === 0"
              >
                <v-radio
                  v-for="(item, key) of CONSTS.YES_NO_RADIO"
                  :key="key"
                  :label="item.title"
                  color="primary"
                  :value="item.value"
                />
              </v-radio-group>
            </span>
          </div>
        </validation-provider>
      </v-col>
    </v-row>
  </div>
</template>
<script>
  import moment from 'moment'
  import InputTemplateDate from '@/components/common/fmsvc/form-templates/InputTemplateDate'
  import InterestForm from './InterestForm.vue'

  export default {
    name: 'FinancingRoundCommonForm',

    components: {
      InterestForm,
      InputTemplateDate,
    },

    props: {
      value: {
        type: Object,
        required: true,
      },
    },

    data: () => ({
      interest: 'No',
      investorData: [{
        invested_capital: '',
        name: '',
      }],
      liquidationPreference: {},
      participatingPreference: {},
      interestData: {},
      includeInterest: false,
      equityIncentivePlanShuffle: 0,
      finallyClosedOn: '',
    }),

    computed: {
      internalValue: {
        get () {
          return this.value
        },
        set (val) {
          this.$emit('input', val)
        },
      },
    },

    watch: {
      value: {
        handler (val) {
          if (val) {
            this.liquidationPreference = this.internalValue.liquidation_preference || {}
            this.investorData = this.internalValue.investors || []
            this.equityIncentivePlanShuffle = this.internalValue.equity_incentive_plan_shuffle || 0
            this.finallyClosedOn = val.finally_closed_on ? moment(val.finally_closed_on).format('MM/DD/YYYY') : ''
            this.participatingPreference = this.liquidationPreference.participating_preference
          }
        },
      },
      finallyClosedOn: {
        handler (val) {
          if (val) {
            this.internalValue.finally_closed_on = moment(val).format('YYYY-MM-DD')
          }
        },
        immediate: true,
      },
    },

    methods: {
      updateInterest (val) {
        if (val && val !== null) {
          this.internalValue.liquidation_preference.interest_terms = this.interestData
          this.internalValue.liquidation_preference.include_interest = true
        } else {
          delete this.internalValue.liquidation_preference.interest_terms
          this.internalValue.liquidation_preference.include_interest = false
        }
      },
      addInvestor () {
        const investorDataObj = {
          invested_capital: '',
          name: '',
        }
        this.investorData.push(investorDataObj)
        this.internalValue.investors = this.investorData
      },
      deleteInvestor (index) {
        this.investorData.splice(index, 1)
      },
      updateValue () {
        this.internalValue.investors = this.investorData
        this.internalValue.liquidation_preference = this.liquidationPreference
        this.internalValue.liquidation_preference.participating_preference = this.participatingPreference
        this.internalValue.liquidation_preference.include_interest = this.includeInterest
        this.internalValue.equity_incentive_plan_shuffle = this.equityIncentivePlanShuffle
        if (this.internalValue.liquidation_preference.include_interest) {
          this.internalValue.liquidation_preference.interest_terms = this.interestData
        } else {
          delete this.internalValue.liquidation_preference.interest_terms
        }
      },
    },
  }
</script>

<style lang="scss" scoped>
  ::v-deep .v-input--radio-group__input {
    flex-direction: row !important;
  }
  ::v-deep .v-input--radio-group--column .v-radio:not(:last-child):not(:only-child) {
    margin-bottom: 0 !important;
    margin-right: 20px;
  }
  ::v-deep .v-input--selection-controls{
    margin-top: 0;
    padding-top:0 ;
    margin-left: 12px;
  }
  .delete-btn {
    background: none !important;
    border: none !important; }  ::v-deep .trs-disabled-btn.theme--light.v-btn.v-btn--disabled:not(.v-btn--flat):not(.v-btn--text):not(.v-btn--outlined) {
    background: none !important;
  }
  </style>
